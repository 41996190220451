import React from "react"

import ProjectAnimations from "../../components/projects/projectAnimations"
import Slide from "../../components/projects/Slide"
import Spacer from "../../components/projects/Spacer"

import "./template-styles.scss"
import "./southern-creations.scss"
import Img from "../../assets/images/southern-creations-images"
import useScreenTranslate from "../../hooks/useScreenTranslate"

import SEO from "../../components/seo"
import RightArrowSVG from "../../assets/svg/right-arrow.svg"
import TransitionLink from "../../components/TransitionLink"
import NavigationOrbs from "../../components/NavigationOrbs"

// const black = "#212931"
// const white = "#ffffff"
const tan = "#DCCEB8"
const green = "#516046"

const SouthernCreationsProjects = () => {
  ProjectAnimations()
  const {
    scrollerSectionRef,
    scrollerScreenContainerRef,
    scrollerScreenRef,
    screenTranslate,
  } = useScreenTranslate({ durationInScreens: 2 })

  return (
    <div className="project southern-creations">
      <SEO
        title="Southern Creations"
        description="The rebranding of an icon of Canterbury furniture design and manufacturing. Brand strategy, logomark, brand platform, website design, tone-of-voice, key messaging and copy content, brand and marketing collateral."
      />

      <NavigationOrbs />

      <Slide className="intro">
        <div className="panel--w-half panel--h-screen panel--image panel--image--full">
          <Img src="sc1" className="h-full" />
        </div>
        <div
          className="panel--w-half panel--h-screen panel--text centered-content"
          style={{ backgroundColor: green, color: tan }}
        >
          <div>
            <h1>Southern Creations</h1>
            <p>
              Southern Creations create beautiful, handcrafted timber furniture.
              The superior workmanship and level of care that goes into every
              finished piece is a reflection of the high esteem in which they
              hold their customers. Their repositioning defines the narrative.
              <br />
              <br />
              Brand strategy, logomark, brand platform, website design,
              tone-of-voice, key messaging and copy content, brand and marketing
              collateral
            </p>
          </div>
        </div>
      </Slide>
      <Slide className="section-1">
        <div
          className="panel--w-half panel--h-screen panel--image panel--image--full"
          style={{ backgroundColor: green }}
        >
          <Img src="sc2" />
        </div>
        <div
          className="panel--w-half panel--h-screen panel--text centered-content"
          style={{ backgroundColor: tan, color: green }}
        >
          <div>
            <p>
              Consistent with the industry, we thought three-dimensionally. The
              logomark was developed around the S and C initials and the idea of
              timber joins. The join connects the parts that create the whole –
              an appropriate metaphor for Southern Creations’ role in
              home-making. This idea shaped the design grid and a flexible
              design platform in natural woodland tones.
            </p>
          </div>
        </div>
      </Slide>

      <Slide className="section-2">
        <Spacer />
        <div
          className="panel--w-half panel--h-screen panel--image panel--image--full"
          style={{ backgroundColor: green }}
        >
          <Img src="sc3" />
        </div>
      </Slide>

      <Slide className="section-3">
        <div className="panel--w-full panel--h-screen panel--image panel--image--full">
          <Img src="sc4" />
        </div>
      </Slide>

      <Slide className="section-4">
        <div
          className="panel--w-half panel--h-screen centered-content "
          style={{ backgroundColor: green, color: tan }}
        >
          <div className="panel--text">
            <p>
              Design for a responsive website that showcases their classic and
              contemporary ranges and communicates their values, strengths,
              story and services.
            </p>
          </div>
        </div>
        <div className="panel--w-half panel--h-screen panel--image panel--image--full">
          <Img src="sc5a" />
        </div>
      </Slide>

      {/* <Slide className="section-4a">
        <Spacer />
        <div className="panel--w-half panel--h-screen panel--image panel--image--full">
          <Img src="sc6" />
        </div>
      </Slide> */}

      <div ref={scrollerSectionRef}>
        <Slide className="section-4a" noWrapper>
          <Spacer />
          <div className="ipad panel--w-half panel--h-screen screen-container">
            <div className="screen-container__inner-container">
              <Img src="sc5" className="screen-container__frame" />
              <div
                className="screen-container__screen "
                ref={scrollerScreenContainerRef}
              >
                <div
                  ref={scrollerScreenRef}
                  className="screen-container__translate"
                  style={{ transform: `translateY(${screenTranslate}%)` }}
                >
                  <Img src="sc5b" className="screen-container__frame" />
                </div>
              </div>
            </div>
          </div>
        </Slide>
      </div>

      <Slide className="section-5">
        <div className="panel--w-full panel--h-screen panel--image panel--image--full">
          <Img src="sc6" />
        </div>
      </Slide>

      <Slide className="section-6">
        <div className="panel--w-full panel--h-screen panel--image panel--image--full">
          <Img src="sc7" />
        </div>
      </Slide>

      <Slide className="section-7">
        <Spacer />
        <div
          className="panel--w-half panel--h-screen panel--text centered-content "
          style={{ backgroundColor: green, color: tan }}
        >
          <div>
            <p>
              Attention to detail, a warm tone of voice, and thoughtfully
              presented product ranges make the new product catalogue a
              compelling sales tool for furniture stores and showrooms.
            </p>
          </div>
        </div>
      </Slide>

      <Slide className="section-8">
        <div className="panel--w-full panel--h-screen panel--image panel--image--full">
          <Img src="sc8" />
        </div>
      </Slide>

      <Slide className="section-9">
        <div className="panel--w-full panel--h-screen panel--image panel--image--full">
          <Img src="sc9" />
        </div>
      </Slide>

      <Slide className="section-10">
        <div className="panel--w-full panel--h-screen panel--image panel--image--full">
          <Img src="sc10" />
        </div>
      </Slide>

      <Slide className="section-11">
        <Spacer />
        <div
          className="panel--w-half panel--h-screen panel--text centered-content "
          style={{ backgroundColor: tan, color: green }}
        >
          <div>
            <p>
              A prototype for a bespoke lamp that brings the drafted logomark
              into the real world. Each rimu lamp will be handcrafted from
              reclaimed materials salvaged from homes destroyed in the
              Christchurch earthquakes.
            </p>
          </div>
        </div>
      </Slide>

      <Slide className="section-12">
        <div className="panel--w-full panel--h-screen panel--image panel--image--full">
          <Img src="sc11" />
        </div>
      </Slide>

      <Slide className="section-13">
        <div className="panel--w-full panel--h-screen panel--image panel--image--full">
          <Img src="sc12" />
        </div>
      </Slide>

      <div className="section-14 flex flex-wrap pin-for-400">
        <Spacer />
        <div
          className="panel--w-half panel--h-screen centered-content "
          style={{ backgroundColor: tan, color: green }}
        >
          <div className="panel--text">
            <p>
              Each few square centimeters of ink and paper carries a brand
              promise of handcrafted quality and care.
            </p>
          </div>
        </div>
      </div>
      <Spacer />

      <Slide className="section-15">
        <div className="panel--w-full panel--h-screen panel--image panel--image--full">
          <Img src="sc13" />
        </div>
      </Slide>

      <Slide className="section-16">
        <div className="panel--w-full panel--h-screen panel--image panel--image--full">
          <Img src="sc1" />
        </div>
      </Slide>

      <Slide className="section-17">
        <Spacer />
        <div
          className="panel--w-half panel--h-screen panel--text centered-content"
          style={{ backgroundColor: green, color: tan }}
        >
          <div>
            <p>
              The rebranding of Southern Creations inspired positive feedback,
              new enquiries and an increase in sales. The tradition of furniture
              that turns houses into homes will continue for new generations of
              New Zealanders. <br />
              <br />
              <br />
              <TransitionLink
                to="/projects/ecan/"
                color="#3D6078"
                className="underline"
              >
                <RightArrowSVG
                  className="inline-block"
                  style={{ width: "0.5em", marginRight: "0.5em" }}
                />
                Next project
              </TransitionLink>
              <br />
              <TransitionLink
                to="/#projects"
                color="white"
                className="underline"
              >
                <RightArrowSVG
                  className="inline-block transform rotate-180"
                  style={{ width: "0.5em", marginRight: "0.5em" }}
                />
                Back to all projects
              </TransitionLink>
            </p>
          </div>
        </div>
      </Slide>
    </div>
  )
}

export default SouthernCreationsProjects
